import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { useMediaPredicate } from "react-media-hook"
import { useStaticQuery, graphql, Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { Container, Section } from "../components/grid/grid"
import imgCard from "../assets/img/precious-samsung/card-platinum.jpg"
import imgBannerPrecious from "../assets/img/precious-samsung/_banner-precious-samsung.jpg"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const PreciousPlatinumPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        page(id: "precious-samsung", idType: URI) {
          title
          slug
          featuredImage {
            mediaItemUrl
          }
          blocks {
            name
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const ogUrl = process.env.BASE_URL + location.pathname
  const wordPress = staticData?.wordPress?.page
  const seo = wordPress?.seo
  const featuredImage = wordPress?.featuredImage?.mediaItemUrl || imgBannerPrecious
  const coverImage = imgBannerPrecious

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    let vLine = document.querySelector(".v-line")
    let langItem = document.querySelectorAll(".lang-item")
    let langItemActive = document.querySelector(".lang-item.active")
    let tnbHeader = document.querySelector(".tnb-header")

    if(resizeScreen){
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }

    for (let i = 0; i < langItem.length; i++) {
      langItem[i].style.color = "#000"
    }

    if (tnbHeader) {
      tnbHeader.id = "tnb-header"
    }

    vLine.style.backgroundColor = "#000"
    langItemActive.style.color = "#AB0204"
  }, [resizeScreen])
  
  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={"Penawaran Harga Special Samsung untuk Member Precious Platinum"}
        fbTitle={"Penawaran Harga Special Samsung untuk Member Precious Platinum"}
        twitterTitle={"Penawaran Harga Special Samsung untuk Member Precious Platinum"}
        url={ogUrl}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        img={"https://www.sinarmasland.com/app/uploads/2024/09/samsung-precious-banner.jpg"}
        fbImg={"https://www.sinarmasland.com/app/uploads/2024/09/samsung-precious-banner.jpg"}
        twitterImg={"https://www.sinarmasland.com/app/uploads/2024/09/samsung-precious-banner.jpg"}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <img src={coverImage} alt="Samsung x Precious" loading="lazy" style={{
        width: "100%",
      }} />
      <Section>
        <Container className={`precious-samsung text-center`}>
          <h1 className="promo-title">
            Samsung x Precious
          </h1>
          <h2 className="promo-subtitle">
            Eksklusif diskon hingga 59%
          </h2>
          <h3 className="member-exclusive">
            Khusus Member Sinar Mas Land Precious
          </h3>
          <span className="call-to-action">
            Nikmati diskonnya sekarang
          </span>
          <img src={imgCard} alt="Samsung x Precious Card Platinum" className="promo-card"/>
          <p className="promo-description">
            Cara Menggunakan Promo Samsung x Sinar Mas Land Precious
          </p>
          <ol className="promo-steps">
            <li><p>Klik button “<strong>Gunakan Promo</strong>” di bawah</p></li>
            <li><p>Halaman <strong>SEIN VIP</strong> Store akan terbuka.</p></li>
            <li><p>Masuk atau daftarkan akun Samsung Anda dengan <strong>email yang terdaftar sebagai member Precious</strong></p></li>
            <li><p>Pilih produk yang Anda inginkan</p></li>
            <li><p>Harga promo otomatis muncul dilayar dan promo berhasil digunakan.</p></li>
            <li><p>VIP Assistant Samsung WhatsApp Contact: +62 838 7837 2527</p></li>
          </ol>
          <Link to="https://www.samsung.com/id/multistore/sein_vip">
            <button className="promo-button">
              GUNAKAN PROMO
            </button>
          </Link>
          <h2 className="terms-title">
            Syarat dan Ketentuan Promo Samsung x Sinar Mas Land Precious
          </h2>
          <ol className="terms-list">
            <li><p>Penawaran promo Samsung x Sinar Mas Land Precious hanya berlaku untuk customer Sinar Mas Land yang terdaftar sebagai member.</p></li>
            <li><p>Promo berlaku selama persediaan masih ada.</p></li>
            <li><p>Konsumen tidak dapat melakukan pembatalan atas transaksi pembelian yang telah dilakukan dalam Promo ini dengan alasan apapun.</p></li>
            <li><p>Pengiriman barang 3 — 7 hari kerja (tidak termasuk akhir pekan dan libur nasional) untuk wilayah Jabodetabek dan maksimal 14 hari kerja untuk luar Jabodetabek</p></li>
            <li><p>PT. Samsung Electronics Indonesia (“<strong>Samsung</strong>”) berhak melakukan pembatalan atas Promo untuk transaksi yang terindikasi tidak valid atau melanggar Syarat dan Ketentuan ini.</p></li>
            <li><p>Dengan melakukan transaksi dan berpartisipasi dalam Promo ini, konsumen dianggap telah menyetujui dan menerima seluruh Syarat dan Ketentuan ini dan perubahannya (jika ada).</p></li>
            <li><p>Jika ada pertanyaan lebih lanjut, konsumen dapat menghubungi Call Center Samsung Indonesia 0800-112-7777 | 0800-112-8888 pada hari Senin — Minggu pukul 06:00 — 22:00 WIB.</p></li>
          </ol>
        </Container>
      </Section>
    </Layout>
  )
}

export default PreciousPlatinumPage
